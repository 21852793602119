import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'

export default function useBranchesList() {
  const toast = useToast()
  const refBranchListTable = ref(null)
  const tableColumns = [
    { key: 'Branch', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBranches = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('branch_name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localBranchesCount = refBranchListTable.value ? refBranchListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localBranchesCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localBranchesCount,
      of: totalBranches.value,
    }
  })

  const refetchData = () => {
    refBranchListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData()
  })
  function filterByBranchCode(arr, code) {
    return arr.filter(item => item.branch_code === code)
  }
  const fetchBranches = (ctx, callback) => {
    const to = perPage.value * (currentPage.value - 1)
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, {
        headers,
        params: {
          search: searchQuery.value,
          order_by: sortBy.value,
          order_sort: isSortDirDesc.value ? 'DESC' : 'ASC',
          limit: perPage.value,
          offset: to,
        },
      })
      .then(response => {
        let dataResp = []
        if (response.data.data !== null) {
          const userData = Vue.$cookies.get('userData')
          const userBranch = userData.branch
          if (userBranch !== null && typeof userBranch !== 'undefined') {
            dataResp = filterByBranchCode(response.data.data, userBranch.branch_code)
          } else {
            dataResp = response.data.data
          }
        totalBranches.value = response.data.data_count
        callback(dataResp)
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Fetching Branches list!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Fetching Branches list!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchBranches,
    tableColumns,
    perPage,
    currentPage,
    totalBranches,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBranchListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
