<template>

    <div>

      <item-add-new
        :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
        :role-options="roleOptions"
        @refetch-data="refetchData"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <!-- Search -->
          </b-row>

        </div>

        <b-table
          ref="refBranchListTable"
          class="position-relative"
          :items="fetchBranches"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Branch -->
          <template #cell(Branch)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.branch_name }} ({{data.item.branch_code}})</span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item

               @click="addStock(  data.item )">
                <feather-icon icon="PlusIcon" />
                <span class="align-middle ml-50">Create Stock Initialization</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDetails(  data.item )">
                <feather-icon icon="ZoomInIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalBranches"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </template>

  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import axios from 'axios'
  import vSelect from 'vue-select'
  import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  // import ItemesListFilters from './ItemesListFilters.vue'
  import BranchList from './BranchList'
  import itemModule from '../itemModule'

  const userRole = ''

  export default {
    components: {
      // ItemesListFilters,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,

      vSelect,
    },
    mounted() {
      this.userRole = this.$cookies.get('UserRole')
      this.$root.$on('refreshTable', text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refBranchListTable.refresh()
    })
    },
    setup() {
      const BRANCH_APP_STORE_MODULE_NAME = 'app-item'

      // Register module
      if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, itemModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

      const isAddNewItemSidebarActive = ref(false)

      const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]

      const {
        fetchBranches,
        tableColumns,
        perPage,
        currentPage,
        totalBranches,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBranchListTable,
        refetchData,

        // UI
        resolveItemRoleVariant,
        resolveItemRoleIcon,
        resolveItemStatusVariant,

        // Extra Filters
        roleFilter,
        statusFilter,
      } = BranchList()

      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ]

      return {

        // Sidebar
        isAddNewItemSidebarActive,
        userRole,
        fetchBranches,
        tableColumns,
        perPage,
        currentPage,
        totalBranches,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBranchListTable,
        refetchData,

        // Filter
        avatarText,

        // UI
        resolveItemRoleVariant,
        resolveItemRoleIcon,
        resolveItemStatusVariant,

        roleOptions,
        statusOptions,

        // Extra Filters
        roleFilter,
        statusFilter,
      }
    },
    methods: {
        deleteItem(id) {
          const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
            ItemID: id.toString(),
          }
            axios
                .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_BRANCH}`, body, { headers })
                .then(response => {
                  console.log(response)
                  if (response.data.Status === 1) {
                    this.$refs.refBranchListTable.refresh()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Congratulation',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Your data has been deleted!',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Something went wrong',
                      },
                    })
                  }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        addStock(data) {
            this.$cookies.set('BranchDetail', data)
            this.$router.push({ name: 'apps-item-stocks-create' })
        },
        showDetails(data) {
            this.$cookies.set('BranchDetail', data)
            this.$router.push({ name: 'apps-item-stocks-details' })
        },
        numberFormat(value) {
          return parseFloat(value).toFixed(2)
        },
      addNew() {
            this.$router.push({ name: 'apps-item-stocks-create' })
      },
    },
  }
  </script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
